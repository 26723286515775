import './search.css';
import { Grid } from 'semantic-ui-react';
import React from 'react';

function Search() {
    return (
        <div className="searchComponent"> 
            <div className="searchContainer">   
                <Grid verticalAlign="middle" centered padded className="searchGrid" columns={2}>
                    <Grid.Row stretched className="removeAll">
                        <Grid.Column className="searchCard" mobile={14} tablet={12} computer={6}>
                            <div className="searchCardContainer">
                                <Grid columns={2} className="setHeight removeAll">
                                    <Grid.Row stretched className="setHeight removeAll ASC">
                                        <Grid.Column className="setHeight setWidth removeAll flexColumn">
                                            <div id="ASC" className="searchCardHeading verticalCenter">Advanced Searching Capability</div>
                                        {/* </Grid.Column>
                                        <Grid.Column className="setHeight removeAll"> */}
                                            <div className="searchCardText verticalCenter">Search through your folders and files with advanced capabilities to locate hard-to-find data on local or cloud storage platforms.</div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Grid.Column>
                        
                        <Grid.Column className="searchCard" mobile={14} tablet={12} computer={3}>
                            <div className="searchCardContainer">
                                <Grid columns={1} className="setHeight removeAll">
                                    <Grid.Row stretched className="setHeight removeAll">
                                        <Grid.Column width={16} className="removeAll">
                                            <div id="report" className="searchCardHeading centerText">Reports</div>
                                            <div className="searchCardText">Gain insights on storage and platform usage, data growth, and help you plan your next data migration.</div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    
                    <Grid.Row stretched className="removePadding">
                        <Grid.Column className="searchCard" mobile={14} tablet={12} computer={3}>
                            <div className="searchCardContainer">
                                <Grid columns={1} className="setHeight removeAll">
                                    <Grid.Row stretched>
                                        <Grid.Column width={16} className="removeAll">
                                            <div id="savedSearch" className="searchCardHeading centerText">Search Tags</div>
                                            <div className="searchCardText">Assign tags to files, folders, and users to quickly retrieve storage insights.</div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Grid.Column>
                        
                        <Grid.Column className="searchCard" mobile={14} tablet={12} computer={6}>
                            <div className="searchCardContainer">
                                <Grid columns={2} className="setHeight removeAll">
                                    <Grid.Row stretched className="setHeight removeAll">
                                        <Grid.Column className="setWidth flexColumn">
                                            <div id="tryingToMigrate" className="searchCardHeading oneWordMe verticalCenter">Looking To Migrate?</div>
                                            <div className="searchCardText verticalCenter">Built for transferring between storage platforms with ease. Packed with features to help you plan and migrate users, folders, and files.</div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </div>
    );
}

export default Search;
