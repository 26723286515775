import './dataInsights.css';
import filetypeWidgetRec from '../../assets/filetypeWidgetRec.png';
import filetypeWidgetSQ from '../../assets/filetypeWidgetSQ.png';
import storagetrendingWidgetSQ from '../../assets/storagetrendingWidgetSQ.png';
import storageusageWidgetTall from '../../assets/storageusageWidgetTall.png';
import totalsizeWidgetSQ from '../../assets/totalsizeWidgetSQ.png';
import { Grid } from 'semantic-ui-react';
import React from 'react';

function DataInsights() {
    return (
        <div className="dataInsightsComponent"> 
            <div className="dataInsightsContainer">
                <Grid container columns={4}>
                    {/* <Grid.Row>
                        <Grid.Column className='' width={4}>
                            <Grid.Row>
                            <div className='dataInsightsHeading'>Actionable Data Insights</div>
                            <div><img src={storagetrendingWidgetSQ} className="widget" alt="widget" /></div>
                            </Grid.Row>
                        </Grid.Column>
                        <Grid.Column className='' width={4}>
                             <div><img src={storageusageWidgetTall} className="widget" alt="widget" /></div>
                        </Grid.Column>
                        <Grid.Column className='' width={8}>
                            <Grid.Row stretched>
                                <div className='widgets'>
                                    <Grid.Column>
                                        <div><img src={filetypeWidgetSQ} className="widget" alt="widget" /></div>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <div><img src={totalsizeWidgetSQ} className="widget" alt="widget" /></div> 
                                    </Grid.Column>
                                </div>
                            </Grid.Row>
                            <Grid.Row>
                            <div><img src={filetypeWidgetRec} className="widget" alt="widget" /></div>
                            </Grid.Row>
                        </Grid.Column>
                    </Grid.Row> */}
                    <div className='dataInsight'>
                        <div className='col1'>
                            <div className='dataInsightsHeading'>Actionable Data Insights</div>
                            <div><img src={storagetrendingWidgetSQ} className="widget" alt="widget" /></div>
                        </div>
                        <div className='col2'>
                            <div><img src={storageusageWidgetTall} className="widget" alt="widget" /></div>
                        </div>
                        <div className='col3'>
                            <div className='col3row1'>
                                <div><img src={filetypeWidgetSQ} className="widget" alt="widget" /></div>
                                <div><img src={totalsizeWidgetSQ} className="widget" alt="widget" /></div> 
                            </div>
                        
                        <div><img src={filetypeWidgetRec} className="widget" alt="widget" /></div>
                        </div>
                    </div>
                    
                </Grid>
            </div>
        </div>
    );
}

export default DataInsights;