import './footer.css';
// import orchestrateDataIcon from '../../assets/orchestratedata.svg';
// import { Button, Grid } from 'semantic-ui-react';
import React from 'react';
import logo from '../../assets/bifrustLogo.svg';




function Footer() {
    return (
        <div className="footerComponent">
            <div className="footerContainer">
                
                <img className='footerLogo' src={logo}  alt="logo" />
                
                <div className='footerItem'>
                    <div className='footerMainLink'>Product</div>
                    <div className='footerSubLink'><a href="#splashBottomSection">Features</a></div>
                    <div className='footerSubLink'><a href="https://docs.bifrust.com" target="_blank" rel="noopener noreferrer"s>Documentation</a></div>
                </div>
                <div className='footerItem'>
                    <div className='footerMainLink'>Company</div>
                    <div className='footerSubLink'>Careers</div>
                    <div className='footerSubLink'>Blog</div>
                </div>
                <div className='footerItem'>
                    <div className='footerMainLink'>Contact</div>
                    <div className='footerSubLink'><a href="https://twitter.com/BiFrustApp" target="_blank" rel="noopener noreferrer">Twitter</a></div>
                    {/* <div className='footerSubLink'>Email</div> */}
                </div>

               
            </div>    
        </div>
    );
}

export default Footer;
